import React from "react";
import { useStaticQuery, graphql } from "gatsby";


import Hero from "@shared/hero";
import { Query } from "@graphql-types";

const HomeHero = () => {
  const { sanityHomePage }: Query = useStaticQuery(graphql`
    {
      sanityHomePage {
          hero {
            ...sanityHero
          }
        }
    }
  `);

  return <Hero data={sanityHomePage?.hero} isHomepage />;
};

export default HomeHero;
