import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";

import { Image } from "@global";
import { MOBILE_BREAKPOINT, assets } from "@util/constants";
import { Container } from "@util/standard";

const ArrowContainer = styled(Container)`
  position: absolute;
  bottom: -17vh;
  left: 50%;
  transform: rotate(90deg);
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display:none;
  }
`;

const AnimatedArrowBlock = () => {
  const [styles, api] = useSpring(() => ({
    from: { y: 5, opacity: 1 },
  }));
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (hover) {
      api.start({
        y: 15,
        opacity: 1,
      });
    } else {
      api.start({
        y: 5,
        opacity: 1,
      });
    }
  }, [hover]);

  const handleScroll = () => {
    if (typeof window !== undefined) {
      const scrollValue = window.innerHeight;
      window.scrollTo({ top: scrollValue, behavior: "smooth" });
    }
    return;
  };


  const AnimatedArrow = () => (
    <animated.div
      style={{
        ...styles,
      }}
    >
      <ArrowContainer>
        <Image
          staticImage={assets.ctaArrow}
          width="34px"
          height="17px"
        />
      </ArrowContainer>
    </animated.div>
  );
  return (
    <Container
      cursor="pointer"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleScroll}
    >
      <AnimatedArrow />
    </Container>
  );
};

export default AnimatedArrowBlock;