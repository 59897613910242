import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import HomeHero from "@components/home/homeHero";
import CapabilitiesBlock from "@components/home/capabilitiesBlock";
import FullWidthContent from "@shared/fullWidthContent";
import LeftRightBlock from "@shared/leftRightBlock";
import { Query } from "@graphql-types";
import { isSanityFullWidthContent, isSanityLeftRightBlock } from "@util/types";
import SEO from "@shared/seo";

const HomePage = () => {
  const { sanityHomePage }: Query = useStaticQuery(graphql`
    {
      sanityHomePage {
        content {
          ... on SanityFullWidthContent {
            ...sanityFullWidthContent
          }
          ... on SanityLeftRightBlock {
            ...sanityLeftRightBlock
          }
        }
        seo {
          ...sanitySeo
        }
      }
    }
  `);

  if (
    sanityHomePage == null ||
    !sanityHomePage.content ||
    sanityHomePage.content.length === 0
  ) {
    return null;
  }

  return (
    <>
      <SEO seoData={sanityHomePage?.seo} />
      <HomeHero />
      <CapabilitiesBlock />
      {sanityHomePage?.content?.map((contentData, index) => {
        if (isSanityFullWidthContent(contentData)) {
          return <FullWidthContent isHomepage data={contentData} key={index} />;
        }
        if (isSanityLeftRightBlock(contentData)) {
          return <LeftRightBlock data={contentData} key={index} />;
        }
        return null;
      })}
    </>
  );
};

export default HomePage;
