import React, { useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

import { Query } from "@graphql-types";
import { Container } from "@util/standard";
import { BlocksContent, Button, Image } from "@global";
import {
  colors,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { linkToButton } from "@util/helper";

const CapabilitiesContainer = styled(Container)`
  text-align: center;
  width: 18%;
  flex-direction: column;
  margin: 60px 0 30px 0;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 40%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 58%;
  }
`;

const BlockContainer = styled(Container)`
  background-color: ${colors.darkBlue};
  justify-content: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled(Container)<{ hoverEffect?: boolean }>`
  height: 52px;
  justify-content: center;
  margin: 0 0 15px 0;
  ${({ hoverEffect }) => hoverEffect && `transform: translateY(-5px);`};
`;

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 65%;
  margin: 0 0 100px 0;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const InfoBlockContainer = styled(Container)`
  width: 80%;
  margin: 5px auto 0 auto;
  flex-direction: column;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 75%;
  }
`;

const MoreInfoContainer = styled(Container)<{ hoverEffect?: boolean }>`
  flex-direction: column;
  ${({ hoverEffect }) => hoverEffect && `transform: translateY(-5px);`};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-bottom: 10px;
  }
`;

const TextContainer = styled(Container)<{ hoverEffect?: boolean }>`
  ${({ hoverEffect }) => hoverEffect && `transform: translateY(-5px);`};
`;

const ButtonContainer = styled(Container)<{ hoverEffect: boolean }>`
  visibility: hidden;
  opacity: 0;
  ${({ hoverEffect }) =>
    hoverEffect && ` visibility: inherit; margin: auto; opacity: 1;`};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    visibility: inherit;
    opacity: 1;
    margin: auto auto 30px auto;
  }
`;

const CapabilitiesBlock = () => {
  const { sanityHomePage }: Query = useStaticQuery(graphql`
    {
      sanityHomePage {
        capabilities {
          ...sanityCapabilitiesInfo
        }
      }
    }
  `);

  if (sanityHomePage == null) {
    return null;
  }
  const [hoverEffectOn, setHoverEffectOn] = useState<number | undefined>();
  const { capabilities } = sanityHomePage;
  if (capabilities == null) return null;
  const { capabilitiesMoreInfo, _rawCapabilitiesInfoDesc } = capabilities;

  return (
    <BlockContainer>
      <CapabilitiesContainer>
        <BlocksContent blocks={_rawCapabilitiesInfoDesc} />
      </CapabilitiesContainer>
      <InfoContainer>
        {capabilitiesMoreInfo &&
          capabilitiesMoreInfo.map((item, index) => {
            const button = linkToButton(item?.linkTitle);

            return (
              <MoreInfoContainer
                key={item?._key}
                onMouseEnter={() => setHoverEffectOn(index)}
                onMouseLeave={() => setHoverEffectOn(99)}
                hoverEffect={hoverEffectOn === index}
              >
                <ImageContainer>
                  <Image
                    image={item?.infoImage?.asset?.gatsbyImageData}
                    width="100%"
                    height="100%"
                    objectFit="contain"
                  />
                </ImageContainer>
                <InfoBlockContainer>
                  <TextContainer>
                    <BlocksContent blocks={item?._rawInfoDesc} />
                  </TextContainer>
                  <ButtonContainer hoverEffect={hoverEffectOn === index}>
                    {item?.linkTitle?.linktext && (
                      <Button
                        {...button}
                        theme="text"
                        arrowWidth="15px"
                        isNormalFont
                        noTextUnderlineOffset
                        underlined="underline 1px"
                        mobilefontSize={11}
                        arrowMargin="auto auto auto 8px"
                      />
                    )}
                  </ButtonContainer>
                </InfoBlockContainer>
              </MoreInfoContainer>
            );
          })}
      </InfoContainer>
    </BlockContainer>
  );
};

export default CapabilitiesBlock;
