import React from "react";
import styled from "styled-components";

import { Maybe, SanityHero } from "@graphql-types";
import { Container } from "@util/standard";
import {
  assets,
  colors,
  colorsRGB,
  IMAC_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
} from "@util/constants";
import { BlocksContent, Image, Video, Button } from "@global";
import { isSanityFlightImage, isSanityVideoUpload } from "@util/types";
import AnimatedArrowBlock from "./animatedArrow";
import { linkToButton } from "@util/helper";

const Wrapper = styled(Container)<{
  height?: string;
  mobileHeight?: string;
  isHomepage?: boolean;
}>`
  position: relative;
  width: 100%;
  height: ${props => props.height ?? "104vh"};
  margin: 0;
  background-color: ${colors.darkBlue};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    height: ${props => (props.isHomepage ? "79vh" : "62vh")};
  }
`;

const Overlay = styled.div<{
  isHomepage?: boolean;
  isCaseStudy?: boolean;
}>`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: 0.5s;
  background-color: ${colorsRGB.heroBlack(0.2)};
  overflow: hidden;
  clip-path: ${props =>
    props.isHomepage
      ? `polygon(0 0, 100% 0%, 100% 85%, 0 100%);`
      : props.isCaseStudy
      ? `none`
      : `polygon(0 0, 100% 0%, 100% 90%, 0 100%)`};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    clip-path: ${props =>
      props.isHomepage
        ? `polygon(0 0, 100% 0%, 100% 95%, 0 100%);`
        : `polygon(0 0, 100% 0%, 100% 96%, 0 100%)`};
  }
`;

const ContentContainer = styled(Container)`
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const LayoutContainer = styled(Container)<{
  isHomepage?: boolean;
}>`
  width: 77%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: ${props =>
      props.isHomepage ? `40% auto auto auto;` : `50% auto auto auto;`};
  }
`;

const BlockContainer = styled(Container)`
  width: 55%;

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 78%;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    flex-direction: column;
  }
`;

const ImageContainer = styled(Container)`
  margin: 1.5% 1% auto auto;
  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    margin: 1.5% 1% auto 0;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 0 15px 0;
  }
`;

const AnimatedArrowContainer = styled(Container)`
  justify-content: center;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const BlocksContentContainer = styled(Container)`
  flex-direction: column;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    p {
      width: 100%;
    }
  }
  @media only screen and (min-width: ${IMAC_BREAKPOINT}px) {
    width: 80%;
  }
`;

const BackgroundImageContainer = styled(Container)<{
  isHomepage?: boolean;
  isCaseStudy?: boolean;
}>`
  width: 100%;
  clip-path: ${props =>
    props.isHomepage
      ? `polygon(0 0, 100% 0%, 100% 85%, 0 100%);`
      : props.isCaseStudy
      ? `none`
      : `polygon(0 0, 100% 0%, 100% 90%, 0 100%)`};
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    clip-path: ${props =>
      props.isHomepage
        ? `polygon(0 0, 100% 0%, 100% 95%, 0 100%);`
        : `polygon(0 0, 100% 0%, 100% 96%, 0 100%)`};
  }
`;

interface Props {
  data: Maybe<SanityHero> | undefined;
  height?: string;
  isHomepage?: boolean;
  isCaseStudy?: boolean;
}

const Hero = ({ data, height, isHomepage, isCaseStudy }: Props) => {
  if (data == null) {
    return null;
  }
  const { backgroundMedia, buttonLink, content } = data;

  const Buttons = () => {
    if (buttonLink && buttonLink.length > 0) {
      const firstButton = linkToButton(buttonLink[0]);
      const secondButton = linkToButton(buttonLink[1]);
      return (
        <Container flexDirection="row" margin="15px 0 0 0">
          <Button
            {...firstButton}
            margin="0 40px 0 0"
            padding="10px 40px"
            dimensions={{ height: "46px" }}
          />
          <Button
            {...secondButton}
            theme="text"
            arrowWidth="15px"
            isNormalFont
            noTextUnderlineOffset
            underlined="underline 1px"
            mobilefontSize={11}
            arrowMargin=" auto auto auto 8px"
          />
        </Container>
      );
    }
    return null;
  };

  const RenderMedia = () => {
    if (backgroundMedia && backgroundMedia.length > 0) {
      const media = backgroundMedia[0];
      if (isSanityFlightImage(media)) {
        return <Image image={media.asset?.gatsbyImageData} objectFit="cover" />;
      }

      if (isSanityVideoUpload(media)) {
        return (
          <Video
            key={media._key}
            url={media.vimeoUrl}
            volume={false}
            pause={true}
            isVisible={true}
          />
        );
      }
    }

    return null;
  };

  return (
    <Wrapper height={height} isHomepage={isHomepage}>
      <Overlay isHomepage={isHomepage} isCaseStudy={isCaseStudy} />
      <BackgroundImageContainer
        isHomepage={isHomepage}
        isCaseStudy={isCaseStudy}
      >
        <RenderMedia />
      </BackgroundImageContainer>
      <ContentContainer>
        <LayoutContainer isHomepage={isHomepage}>
          <BlockContainer>
            <ImageContainer>
              <Image
                staticImage={assets.heroTriangle}
                width="29.5px"
                height="17px"
              />
            </ImageContainer>
            <BlocksContentContainer>
              <BlocksContent
                pWidth={isHomepage ? "75%" : "55%"}
                blocks={content?._rawColumnContent}
              />
              {isHomepage && <Buttons />}
            </BlocksContentContainer>
          </BlockContainer>
          <AnimatedArrowContainer>
            {isHomepage && <AnimatedArrowBlock />}
          </AnimatedArrowContainer>
        </LayoutContainer>
      </ContentContainer>
    </Wrapper>
  );
};

export default Hero;
